import DpFormMixin from 'src/core/components/DpForm/DpFormMixin.js'
import DpForm from 'src/core/components/DpForm/DpForm2.vue'
import MidiaModel from 'src/model/veiculo/MidiaModel.js'
import NotificacaoMixin from 'src/core/mixin/NotificacaoMixin'
import Upload from 'src/components/Upload.vue'

export default {
  name: 'MidiaForm',
  components: { DpForm, Upload },
  mixins: [DpFormMixin, NotificacaoMixin],
  data () {
    return {
      modelPadrao: new MidiaModel()
    }
  },
  mounted () {
  },
  methods: {

  }
}
